import Vue from 'vue'
import Vuex from 'vuex'

import otp from './modules/otp'
import auth from './modules/auth'
import category from './modules/category'
import user from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  getters: {
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    }
  },
  actions: {
  },
  modules: {
    otp,
    auth,
    category,
    user
  }
})
