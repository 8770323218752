<template>
  <div class="carousel-container">
    <div class="swiper-container" id="advertisement-carousel">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank" class="position-relative d-block"> 
            <img src="@/assets/ad-1-crop.jpeg"/>            
            <!-- <div class="position-absolute logo-wrapper">
              <img src="@/assets/instagram-logo.svg"/>
            </div> -->
          </a>
        </div>
        <div class="swiper-slide">
          <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank" class="position-relative d-block">
            <img src="@/assets/ad-2-crop.jpeg"/>
            <!-- <div class="position-absolute logo-wrapper">
              <img src="@/assets/instagram-logo.svg"/>
            </div> -->
          </a>
        </div>
        <div class="swiper-slide">
          <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank" class="position-relative d-block">
            <img src="@/assets/ad-3-crop.jpeg"/>
            <!-- <div class="position-absolute logo-wrapper">
              <img src="@/assets/instagram-logo.svg"/>
            </div> -->
          </a>
        </div>
        <div class="swiper-slide position-relative">
          <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank" class="position-relative d-block">
            <img src="@/assets/ad-4-crop.jpeg"/>
            <!-- <div class="position-absolute logo-wrapper">
              <img src="@/assets/instagram-logo.svg"/>
            </div> -->
          </a>
        </div>
      
      </div>
      <!-- <div class="swiper-pagination"></div> -->
      <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank" class="position-relative d-block">
        <div class="position-absolute logo-wrapper">
          <img src="@/assets/instagram-logo.svg"/>
        </div>
      </a>
    </div>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.css';

export default {
  data() {
    return {
    };
  },
  mounted() {
    this.initializeSwiper();
  },
  methods: {
    initializeSwiper() {
      new Swiper('#advertisement-carousel', {
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
          450: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        },
      });
    },
  },
};
</script>

<style scoped>

.carousel-container {
  width: 100%;
  max-width: 1200px;
  margin: 5rem auto 0 auto;
  position: relative; /* Important for positioning the navigation */
  /* overflow: hidden; */
}

.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden; /* Allow overflow for navigation buttons */
  max-width: 1024px;
  position: relative;
}

.swiper-slide img {
  width: 100%;
  height: auto;
}

.swiper-button-next,
.swiper-button-prev {
  --swiper-navigation-size: 30px;
  color: #ed1969;
  position: absolute; /* Position the buttons */
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.swiper-button-next {
  right: 0px; /* Move the next button to the right of the container */
}

.swiper-button-prev {
  left: 0px; /* Move the previous button to the left of the container */
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-weight: bold;
  --swiper-navigation-size: 30px;
}


.logo-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 10px;
  z-index: 100;
}

.logo-wrapper img {
  width: 30px;
  height: 30px;

}

.logo {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
}
</style>
