import axios from 'axios';
import EventBus from './eventBus'
import { Notification } from 'element-ui';
const instance = axios.create({
  baseURL: 'https://api.wehelpyou.az/api',
  timeout: 50000, // Set your desired timeout
  headers: {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}`: null
  }
});

instance.interceptors.request.use(
  (config) => {
    // Show spinner or loader before making the request
    // You can emit an event, use Vuex store, or any other method to toggle the spinner visibility globally
    // For example, using a global event bus
    EventBus.$emit('showSpinner');
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    EventBus.$emit('hideSpinner');
    return response;
  },
  (error) => {
    EventBus.$emit('hideSpinner');
    if (error.response) {
      if (error.response.status === 500 || error.response.status === 400) {

        if (error.response.data?.message && Array.isArray(error.response.data?.message) && error.response.data?.message.length) {
          Notification.error({
            title: 'Xəta',
            message: error.response.data.message[0],
            duration: 4500
          });
        }
        else if (error.response.data?.message) {
          Notification.error({
            title: 'Xəta',
            message: error.response.data.message,
            duration: 4500
          });
        }
        else {
          Notification.error({
            title: 'Xəta',
            message: 'Sorğu zamanı xəta baş verdi.',
            duration: 4500
          });
        }
      }
      else if (error.response.data && error.response.status !== 401 && error.response.status !== 404) {
        Notification.error({
          title: 'Xəta',
          message: error.response.data,
          duration: 4500
        });
      }
    } 
    else if (error.request) {
      if (error.code === 'ECONNABORTED') {
        Notification.error({
          title: 'Xəta',
          message: 'Sorğu zaman vaxt limitini aşmışdır.',
          duration: 4500
        });
      }
    }
    else {
      Notification.error({
        title: 'Xəta',
        message: 'Sorğu zamanı xəta baş verdi.',
        duration: 4500
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
