<template>
  <div>
    <div class="row justify-content-center" style="margin-top: 5rem;">
      <div class="col-auto">
        <img src="@/assets/why-logo.svg" alt="">
      </div>
    </div>
    <div class="row justify-content-center" style="margin-top: 2.75rem">
      <div class="col-auto">
        <router-link :to="{name: 'home', hash: '#about'}" class="v-footer-link">Haqqımızda</router-link>
        <!-- <a href="/#about" class="v-footer-link">Haqqımızda</a> -->
      </div>
      <div class="col-auto">
        <router-link :to="{name: 'categories'}" class="v-footer-link">Xidmətlər</router-link>
      </div>
      <!-- <div class="col-auto">
        <router-link to="#" class="v-footer-link">VIP</router-link>
      </div> -->
      <div class="col-auto">
        <router-link :to="{name: 'employees'}" class="v-footer-link">Mütəxəssislər</router-link>
      </div>
    </div>
    <div class="row justify-content-center" style="margin-top: 2rem;">
      <div class="col-auto">
        <a href="https://www.instagram.com/p/DAIy3dnI_o3/" target="_blank">
          <img src="@/assets/instagram-logo.svg" alt="">
        </a>
      </div>
      <div class="col-auto">
        <a href="https://www.facebook.com/share/nvBmvvM4fu93sHxb/?mibextid=qi2Omg" target="_blank">
          <img src="@/assets/fb-icon-1.svg" alt="">
        </a>
      </div>
    </div>
    <div class="row justify-content-center" style="margin-top: 2.5rem;">
      <div class="col-auto" style="width: 50rem">
        <hr>
      </div>
    </div>
    <div class="row justify-content-center" style="margin-top: 2.5rem">
      <div class="col-auto">
        Copyright © 2024. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.v-footer-link {
  color: var(--Neutral-black, var(--black, #111));
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 144.444% */
  text-decoration: none;
}
</style>