<template>
  <div class="spinner-overlay">
    <div class="spinner-border" role="status" style="color: #ED1969">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's on top of other elements */
}
</style>