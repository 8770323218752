<template>
  <div class="d-flex flex-column flex-sm-row">
    <a href="#" class="link">
      <img src="@/assets/google-icon.svg" width="20" height="20"/>
    </a>
    <a href="#" class="link">
      <img src="@/assets/apple-icon.svg" width="20" height="20"/>
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.link {
  border: 1px solid #D8DADC;
  border-radius: 16px;
  width: 149px;
  height: 56px;
  /* text-align: center; */
  /* line-height: 56px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.link:last-child {
  margin-left: 24px;
}

@media (max-width: 576px) {
  .link:last-child {
    margin-top: 24px;
    margin-left: 0;
  }
}
</style>