import axios from '@/axios'

const state = {
  categories: [],
  loading: false
}

const mutations = {
  setCategories(state, categories) {
    state.categories = categories
  },
  setLoading(state, loading) {
    state.loading = loading
  }
}

const getters = {}

const actions = {
  getAllCategories({commit}) {
    commit('setLoading', true)
    return new Promise((resolve, reject) => {
      axios.get('/category/all')
        .then(res => {
          commit('setCategories', res.data)
          setTimeout(() => {
            commit('setLoading', false)
          }, 1000);
          resolve(res.data)
        })
        .finally(() => {
          commit('setLoading', false)
        })

    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}