<template>
  <div>
    <spinner v-if="loading" />
    <navbar />
    <router-view />
    <!-- <footer /> -->
  </div>
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import { mapState } from 'vuex';
import Spinner from './components/Spinner.vue';
import EventBus from './eventBus';
// import Footer from '@/components/Footer.vue'

export default {
  data() {
    return {
      loading: false
    }
  },
  components: {
    Navbar,
    // Footer
    Spinner
  },
  computed: {
    ...mapState('auth', [
      'userInfo'
    ])
  },
  created() {

    EventBus.$on('showSpinner', () => {
      this.loading = true;
    });
    // Listen for the 'hideSpinner' event
    EventBus.$on('hideSpinner', () => {
      this.loading = false;
    });

    const accessToken = localStorage.getItem('accessToken')
    this.$store.commit('auth/setAccessToken', accessToken)
    
    if (accessToken) {
      this.$store.dispatch('auth/getUserInfo')
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('auth/logout')
          }
        })
    }
    // this.$store.dispatch('category/getAllCategories')
  },
  destroyed() {
    // Remember to clean up event listeners
    EventBus.$off('showSpinner');
    EventBus.$off('hideSpinner');
  }
}
</script>
<style>
@import "@/assets/normalize.css";

body {
  font-family: "Roboto", sans-serif;
}

.selector-for-some-widget {
  box-sizing: content-box;
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1250px;
  }
}
</style>
