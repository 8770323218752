import axios from '@/axios'

const state = {
  otpKey: null
}

const mutations = {
  setOTPKey(state, key) {
    state.otpKey = key
  }
}

const getters = {}

const actions = {
  newSendOTP({commit}, {contact, email, type}) {
    return new Promise((resolve, reject) => {
      axios.post('/otp/send', {contact, email, type})
        .then(res => {
          commit('setOTPKey', res.data.key)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendOTP({commit}, email) {
    return new Promise((resolve, reject) => {
      axios.post('/otp/send', {email})
        .then(res => {
          commit('setOTPKey', res.data.key)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  verifyOTP({commit, state}, otp) {
    return new Promise((resolve, reject) => {
      axios.post('/otp/verify', {key: state.otpKey, otp})
        .then(res => {
          commit('auth/setAccessToken', `${res.data.access_token}`, {root: true})
          localStorage.setItem('accessToken', `${res.data.access_token}`)
          axios.defaults.headers['Authorization'] = `Bearer ${res.data.access_token}`

          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}