import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../views/Homepage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Homepage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('@/views/Testing.vue')
  },
  {
    path: '/create-account',
    name: 'createAccount',
    component: () => import('@/views/CreateAccount.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/categories/:categoryId',
    name: 'subCategories',
    component: () => import('@/views/SubCategories.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/Categories.vue')
  },
  {
    path: '/employees/:id',
    name: 'employeeDetails',
    component: () => import('@/views/EmployeeDetails.vue')
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('@/views/Employees.vue')
  },
  // {
  //   path: '/favorites',
  //   name: 'favorites',
  //   component: () => import('@/views/Favorites.vue')
  // },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  // {
  //   path: '/testing-carousel',
  //   name: 'testingCarousel',
  //   component: () => import('@/components/NewCarousel.vue')
  // },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) resolve(savedPosition)
      else if (to.hash) resolve({selector: to.hash})
      else resolve({x:0, y: 0})
    })
  }
})


router.beforeEach((to, from, next) => {

  const accessToken = localStorage.getItem('accessToken')

  if (to.name === 'profile') {
    if (accessToken) next()
    else next({name: 'login'})
  }
  else next()
});

// router.afterEach((to, from) => {
//   if (!to.hash && !from.hash && !from.meta.keepScrollPosition) {
//     window.scrollTo(0, 0);
//   }
// })

export default router
