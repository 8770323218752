import axios from '@/axios'

const state = {
  accessToken: null,
  userInfo: null
}

const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  logout(state) {
    state.userInfo = null
    state.accessToken = null
    axios.defaults.headers['Authorization'] = null
    localStorage.removeItem('accessToken')
  }
}

const getters = {}

const actions = {
  
  login({commit}, {username, password}) {
    return new Promise((resolve, reject) => {
      axios.post('/auth/login', {userName:username, password})
        .then(res => {
          commit('setAccessToken', `${res.data.access_token}`)
          localStorage.setItem('accessToken', `${res.data.access_token}`)
          axios.defaults.headers['Authorization'] = `Bearer ${res.data.access_token}`
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserInfo({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/auth/user')
        .then(res => {
          commit('setUserInfo', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}