<template>
  <div class="container" style="margin-top: 5rem;">
    <AdvertisementCarousel style="margin-bottom: 10rem;"/>
    <div class="row align-items-center flex-column flex-lg-row">
      <div class="col">
        <div class="v-homepage-text">
          Biz sizə yardımcı olacayıq!
        </div>
        <p class="v-homepage-text-1">
          WHY, qadınların həyatında əsas rol oynayan və onlara dəstək olan bir resursdur. WHY xidmətləri vasitəsilə,
          qadınlar öz mövqelərini gücləndirə bilər, öz potensiallarını daha çox aşkar edə bilər və həyatlarında daha çox
          rahatlıq və şəfqət hiss edə bilərlər.
        </p>
        <!-- <div class="d-flex flex-column flex-sm-row  align-items-center align-items-sm-baseline justify-content-sm-center justify-content-lg-start">
          <button class="btn v-homepage-btn-primary mr-0 me-sm-4" >
            Müraciət et
          </button>
          <button class="btn v-homepage-btn-secondary mt-2 mt-sm-0">
            Müraciət et
          </button>
        </div> -->
        <MobileAppLinks />
      </div>
      <div class="col">
        <img src="@/assets/homepage-img-1.png" class="img-fluid mt-5 mt-lg-0">
      </div>
    </div>

    <div class="row align-items-center flex-column flex-lg-row" style="margin-top: 10rem;" id="about">
      <div class="col order-2 order-lg-1">
        <img src="@/assets/homepage-img-2.png" alt="about image" class="img-fluid mt-5 mt-lg-0">
      </div>
      <div class="col order-1 order-lg-2">
        <div class="v-homepage-text">
          Haqqımızda
        </div>
        <p>
          WHY, qadınlar üçün fərqli sahələrdə lazım olan xidmətləri bir mərkəzdə toplayan bir platforma təklif edir. Bu platforma, qadınların günlük həyatlarında sağlamlıq, məişət işləri, məsləhət, mədəniyyət və daha bir çox sahədə ehtiyac duyduqları xidmətləri asanlıqla əldə etməklərinə kömək edir.
          <br /> <br />
          WHY, qadınların həyatını təkmilləşdirmək və onlara fərqli sahələrdə yardım etmək məqsədi ilə yaradılmışdır. Bu platforma sayəsində, qadınlar istədikləri vaxtda və məkanda onlara lazım olan xidmətləri axtara, seçə və bu xidmətlərdən yararlana bilərlər. Məsələn, sağlamlıq sahəsində mütəxəssislərdən məsləhət almaq, məişət işləri üçün yardım almaq və ya mədəniyyət tədbirlərinə qatılmağı planlamaq kimi fərqli xidmətlərə çatmaq mümkündür.

        </p>
      </div>
    </div>
    <ServicesCarousel style="margin-top: 10rem;" id="categories"/>
    <div class="row align-items-center flex-column flex-lg-row" style="margin-top: 110px">
      <div class="col">
        <div class="v-homepage-text">
          Niyə biz?
        </div>
        <p class="v-homepage-text-1">
          WHY platforması qadınların həyatını daha rahat və səmərəli etmək məqsədilə, müxtəlif xidmətləri təklif edən müəssisələr və tərəfdaşlarla əlaqə yaratmağa imkan verir. Ən yaxşı təcrübəni təmin etmək üçün WHY Service, təcrübələri və təklif olunan xidmətlərdə reytinqləri də birləşdirərək istifadəçilərin doğru seçimlər etməsinə kömək edir. İstifadəçilər məkan parametrləri vasitəsilə ehtiyac duyduqları xidmətləri asanlıqla tapa biləcəkləri kimi, öz maraq dairələri üzrə digər xidmətlərdən də yararlana bilərlər.
          <br /> <br />
          Bu platforma qadınların həyatında əsas rol oynayan və onlara dəstək olan bir resursdur. WHY vasitəsilə, qadınlar öz mövqelərini gücləndirə, potensiallarını daha çox aşkar edə və həyatlarına daha çox rahatlıq gətirə bilərlər. 
        </p>
      </div>
      <div class="col">
        <img src="@/assets/why-us-img.png" alt="about image" class="img-fluid">
      </div>
    </div>
    <!-- <div >
      <img src="@/assets/advertisement.png" alt="advertisement" class="img-fluid" width="100%">
    </div> -->
    <UsersCarousel style="margin-top: 10rem;"/>
    <Footer style="margin-bottom: 67px; margin-top: 160px"/>
  </div>
</template>

<script>
import ServicesCarousel from '@/components/ServicesCarousel.vue'
import VIP from '@/components/VIP.vue'
import UsersCarousel from '@/components/UsersCarousel.vue'
import AdvertisementCarousel from '@/components/AdvertisementCarousel.vue'
import Footer from '@/components/Footer.vue'
import MobileAppLinks from '@/components/MobileAppLinks.vue'
export default {
  name: 'HomeView',
  components: {
    ServicesCarousel,
    VIP,
    UsersCarousel,
    AdvertisementCarousel,
    Footer,
    MobileAppLinks
  }
}
</script>
<style scoped>
.v-homepage-text {
  color: var(--Neutral-black, var(--black, #111));
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  /* 128.571% */
  margin-bottom: .75rem;
}

.v-homepage-text-1 {
  color: var(--Neutral-dark-gray, #393939);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
  margin-bottom: 2.25rem;
}

.v-homepage-btn-primary {
  border-radius: 1.875rem;
  background: var(--Primary-Pink-700, #ED1969);
  width: 11.5rem;
  padding: 0.75rem 1rem;
  text-align: center;
  color: var(--Neutral-white, #FFF);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.v-homepage-btn-secondary {
  border-radius: 1.875rem;
  border: 1px solid var(--Primary-Pink-700, #ED1969);
  width: 11.5rem;
  padding: 0.75rem 1rem;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--Primary-Pink-700, #ED1969);
}

.v-header-link {
  color: var(--Primary-Pink-700, #ED1969);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 144.444% */
  text-decoration: none;
}
</style>
