import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/az'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/az';
import scrollable from './directives/scrollable'

Vue.use(DatePicker)
Vue.use(Element, { locale })
Vue.directive('scrollable', scrollable);

Vue.prototype.$axios = axios;
Vue.config.productionTip = false

Vue.directive('numeric-only', {
  bind(el, binding, vnode) {
    el.addEventListener('input', (event) => {
      event.preventDefault()
      let value = event.target.value;
      // Replace any non-numeric characters with an empty string
      console.log(value, value.replace(/[^0-9]/g, ''))
      value = value.replace(/[^0-9]/g, '');
      // Update the v-model with the numeric value
      vnode.context.$emit('input', value);
    });
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
